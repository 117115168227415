import React from 'react';
import { LightModeProvider } from './src/contexts/LightModeContext';

export const wrapRootElement = ({ element }) => (
  <LightModeProvider>{element}</LightModeProvider>
);

export const onInitialClientRender = () => {
  const isLightMode = localStorage.getItem('lightMode') === 'true';
  document.body.classList.toggle('light-mode', isLightMode);
};
