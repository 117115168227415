import React, { createContext, useContext, useState, useEffect } from 'react';

const LightModeContext = createContext();

export const LightModeProvider = ({ children }) => {
  const [isLightMode, setIsLightMode] = useState(false);

  useEffect(() => {
    const storedLightMode = localStorage.getItem('lightMode') === 'true';
    setIsLightMode(storedLightMode);
    updateBodyClass(storedLightMode);
  }, []);

  const updateBodyClass = (lightMode) => {
    if (typeof document !== 'undefined') {
      document.body.classList.toggle('light-mode', lightMode);
    }
  };

  const toggleLightMode = () => {
    setIsLightMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('lightMode', newMode.toString());
      updateBodyClass(newMode);
      return newMode;
    });
  };

  return (
    <LightModeContext.Provider value={{ isLightMode, toggleLightMode }}>
      {children}
    </LightModeContext.Provider>
  );
};

export const useLightMode = () => {
  const context = useContext(LightModeContext);
  if (context === undefined) {
    throw new Error('useLightMode must be used within a LightModeProvider');
  }
  return context;
};
